import { render, staticRenderFns } from "./SignUpTeacher.vue?vue&type=template&id=d49dbb54&scoped=true"
import script from "./SignUpTeacher.vue?vue&type=script&lang=js"
export * from "./SignUpTeacher.vue?vue&type=script&lang=js"
import style0 from "./SignUpTeacher.vue?vue&type=style&index=0&id=d49dbb54&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d49dbb54",
  null
  
)

export default component.exports