import axios from "axios";
import store from "../store";

const instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export const setupPublicAxiosInstance = () => {
  instance.defaults.baseURL = store.getters["config/publicUrl"];
  console.log(instance.defaults.baseURL)
};

export default instance;
