<template>
  <v-col cols="10" lg="7" xl="5" class="signup-wrapper">
    <v-row no-gutters class="mt-10 mb-10" justify="center">
      <img
        src="@assets/landingPage/HG_LOGO.webp"
        alt="hackersgen logo"
        class="hg-logo"
      />
    </v-row>
    <GoBackButton />
    <div v-if="step === 1" class="login-card register-card mb-8">
      <v-row dense>
        <v-col>
          <h2 class="registration-title pb-6">Registrati</h2>
          <v-form v-model="form" class="form-font">
            <v-row dense>
              <v-col cols="12" sm="6" class="pb-0 mb-0">
                <v-text-field
                  v-model="name"
                  :error-messages="nameErrors"
                  :rules="[rules.required, rules.minTwoCharacters]"
                  label="Nome"
                  required
                  outlined
                  dark
                  background-color="transparent"
                  data-cy="input-first-name"
                  @input="$v.name.$touch"
                  @blur="$v.name.$touch"
                />
              </v-col>

              <v-col cols="12" sm="6" class="pb-0 mb-2">
                <v-text-field
                  v-model="surname"
                  :error-messages="surnameErrors"
                  :rules="[rules.required, rules.minTwoCharacters]"
                  label="Cognome"
                  required
                  outlined
                  dark
                  background-color="transparent"
                  data-cy="input-last-name"
                  @input="$v.surname.$touch"
                  @blur="$v.surname.$touch"
                />
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12" class="mb-0 pb-0">
                <v-text-field
                  v-model="email"
                  label="Email"
                  hint="Utilizza l'indirizzo email sul quale hai ricevuto l'invito"
                  required
                  dark
                  background-color="transparent"
                  outlined
                  :rules="[rules.email, rules.required]"
                  data-cy="input-email"
                />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, rules.passwordRules]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password"
                  hint="Almeno 8 caratteri"
                  required
                  background-color="transparent"
                  dark
                  outlined
                  counter
                  data-cy="input-psw"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, samePassword]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Conferma password"
                  background-color="transparent"
                  dark
                  outlined
                  counter
                  data-cy="input-psw2"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <p class="white--text font-weight-light text-body-2">
                  Sottoscrivendo questa form, do il consenso a processare i miei
                  dati personali, come previsto dalla Legge Europea 679/2016.
                  Per ulteriori dettagli vedi la
                  <a
                    href="https://www.sorint.com/privacy-policy/"
                    target="_blank"
                    class="colored"
                    rel="noopener"
                  >
                    Sorint.lab Privacy Policy
                  </a>
                </p>

                <p
                  class="white--text font-weight-light text-body-2 text-justify"
                >
                  <a
                    href="https://www.sorint.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    Sorint.lab S.p.A.</a
                  >
                  dispone di una propria rete di partner autorizzati con il
                  principale obiettivo di fornire il miglior servizio possibile
                  ai propri clienti. Iscrivendoti, accetti che
                  <a
                    href="https://www.sorint.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    Sorint.lab S.p.A.</a
                  >
                  possa condividere parte dei tuoi dati con i suoi partner.
                </p>
                <v-checkbox
                  v-model="isAtLeast14"
                  label="Ho già compiuto 14 anni"
                  dark
                  hide-details
                  class="mb-2"
                  data-cy="check-fourteen"
                  :rules="[rules.required]"
                  color="#F39200"
                />
                <p
                  class="ml-2 mb-2 white--text font-weight-light text-body-2 text-justify"
                >
                Se hai meno di 14 anni e vuoi registrarti, invia un'email ad 
                  <a
                    href="mailto:hackersgen@sorint.com"
                    target="_blank"
                    rel="noopener"
                  >
                     hackersgen@sorint.com</a
                  >
                </p>
                <v-checkbox
                  v-model="newsletter"
                  label="Accetta ricezione newsletter"
                  dark
                  hide-details
                  class="mb-2"
                  data-cy="check-newsletter"
                  color="#F39200"
                />
                <p
                  class="ml-2 white--text font-weight-light text-body-2 text-justify"
                >
                  Accetto che
                  <a
                    href="https://www.sorint.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    Sorint.lab S.p.A.</a
                  >
                  e le sue società affiliate possano utilizzare i miei dati
                  personali per inviarmi informazioni su prodotti, servizi,
                  eventi e offerte speciali tramite email.
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="d-flex justify-center justify-sm-start white--text font-weight-light"
          cols="12"
        >
          Sei già registrato?
          <a
            class="ml-1 white--text font-weight-medium"
            @click="() => gotoSignin()"
            >Clicca qui per il login
          </a>
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            color="#F39200"
            :disabled="!form"
            dark
            class="register-btn"
            @click="() => step++"
          >
            Avanti
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-if="step === 2" class="login-card register-card mb-8">
      <v-row dense>
        <v-col>
          <h2 class="registration-title pb-6">Ci siamo quasi!</h2>
        </v-col>
      </v-row>
      <v-row
        ><v-col
          ><v-form v-model="form" class="form-font">
            <v-row dense class="mb-1">
              <v-col cols="12" class="mb-2 pb-0">
                <v-select
                  v-model="currentClass"
                  :items="classes"
                  item-text="text"
                  item-value="value"
                  label="Quale classe stai frequentando?"
                  hide-details
                  outlined
                  dark
                  :rules="[rules.required]"
                  background-color="transparent"
                  data-cy="select-input-class"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" class="mb-0 pb-0">
                <v-text-field
                  v-model="secondaryEmail"
                  label="Email secondaria"
                  hint="Utilizza il tuo indirizzo email personale"
                  dark
                  background-color="transparent"
                  outlined
                  :rules="[rules.email]"
                  data-cy="input-secondary-email"
                />
              </v-col>
            </v-row>
            <p class="ml-2 white--text font-weight-light text-body-2">
              Inserisci una tua mail personale:
              <ul>
                <li>
                  ti servirà per accedere alla piattaforma quando la tua 
                  mail istituzionale sarà disattivata
                </li>
                <li>
                  ti potrebbe servire per recuperare le credenziali in caso 
                  di smarrimento
                </li>
                <li>non ti manderemo spam, promesso!</li>
                <li>
                  potrai cambiarla o cancellarla in qualsiasi momento
                  dalle impostazioni del tuo profilo
                </li>
              </ul>
            </p>
            <v-checkbox
              v-model="can_contact"
              label="Ricevi possibili offerte di lavoro"
              dark
              color="#F39200"
              hide-details
              data-cy="check-can_contact"
            />
            <p class="ml-2 mt-2 white--text font-weight-light text-body-2">
              <span class="font-weight-bold"
                >La fortuna non esiste: esiste il momento in cui il talento
                incontra l'opportunità!</span
              ><br>
              E tu non perdere l'opportunità di collaborare (full-time,
              part-time, stage o tirocini) con
              <a href="https://www.sorint.com/" target="_blank" rel="noopener">
                Sorint.lab S.p.A.</a
              >
              e le sue società affiliate! Potrai dare o revocare il consenso in
              qualsiasi momento dalle impostazioni del tuo profilo.
            </p>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            color="#F39200"
            dark
            class="register-btn mr-2"
            @click="()=> step--"
          >
            Indietro
          </v-btn>
          <v-btn
            :loading="loading"
            color="#F39200"
            :disabled="!form"
            dark
            class="register-btn"
            @click="submit"
          >
            Registrati
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import GoBackButton from "@cmp-shared/utils/GoBackButton.vue";
import { pushAuthUrl, pushPlatformUrl } from "@/utils/externalRouter";

const NAME_MAX_LENGTH = 10;

export default {
  name: "SignUpStudent",
  components: { GoBackButton },
  mixins: [validationMixin],
  validations: {
    name: { required, maxLength: maxLength(NAME_MAX_LENGTH) },
    email: { required, email },
    surname: { required },
  },
  data() {
    return {
      name: "",
      email: "",
      step: 1,
      surname: "",
      currentClass: "",
      secondaryEmail: "",
      form: false,
      privacy: true,
      newsletter: true,
      isAtLeast14: false,
      can_contact: false,
      show1: false,
      showPassword: false,
      loading: false,
      password: "",
      confirmPassword: "",
      classId: this.$route.params.classId,
      classes: [
        { text: "1ª", value: "1" },
        { text: "2ª", value: "2" },
        { text: "3ª", value: "3" },
        { text: "4ª", value: "4" },
        { text: "5ª", value: "5" },
      ],
      rules: {
        required: (value) => Boolean(value) || "Campo obbligatorio.",
        min: (v) => v.length >= 8 || "Minimo 8 caratteri",
        minTwoCharacters: (v) =>
          v.length >= 2 || "Inserisci almeno due caratteri",
        passwordRules: (v) => {
          const errors = [];
          if (v.length < 8) {
            errors.push("almeno 8 caratteri");
          }
          if (!v.match(/[a-z]/)) {
            errors.push("almeno una lettera minuscola");
          }
          if (!v.match(/[A-Z]/)) {
            errors.push("almeno una lettera maiuscola");
          }
          if (!v.match(/[0-9]/)) {
            errors.push("almeno un numero");
          }
          return errors.length > 0
            ? `La password deve contenere ${errors.join(", ")}`
            : true;
        },
        email: (v) =>
          !v ||
          /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/.test(
            v
          ) ||
          `Indirizzo email non valido`,
      },
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const studentEmail = urlParams.get("email");

    if (studentEmail) {
      this.email = studentEmail;
    }
  },
  computed: {
    ...mapGetters("config", ["platformEnv"]),

    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) {
        return errors;
      }
      if (!this.$v.name.required) {
        errors.push("Nome richiesto");
      }
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) {
        return errors;
      }
      if (!this.$v.email.email) {
        errors.push(`L'email deve essere valida`);
      }
      if (!this.$v.email.required) {
        errors.push("Email richiesta");
      }
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.surname.$dirty) {
        return errors;
      }
      if (!this.$v.surname.required) {
        errors.push("Cognome richiesto");
      }
      return errors;
    },
    samePassword() {
      return (
        this.password === this.confirmPassword || "Le password non coincidono"
      );
    },
  },
  methods: {
    ...mapActions("auth", ["signUpStudent", "login", "loginOtp"]),

    async submit() {
      this.loading = true;
      try {
        await this.signUpStudent({
          name: this.name,
          surname: this.surname,
          email: this.email.trim().toLowerCase(),
          password: this.password,
          classId: this.classId,
          privacy: this.privacy,
          newsletter: this.newsletter,
          //TODO: add can_contact
        });

        // if (
        //   this.platformEnv !== "development" &&
        //   this.platformEnv !== "production"
        // ) {
        //   await this.loginOtp({
        //     email: this.email.trim().toLowerCase(),
        //     password: this.password,
        //   });
        //   pushAuthUrl("/verify-otp");
        // } else {
          const user = await this.login({
            email: this.email.trim().toLowerCase(),
            password: this.password,
          });
          if (!user) return;
          return pushPlatformUrl("/auth/sso", user.access_token);
        // }
      } catch (err) {
        this.loading = false;
      }
    },
    gotoSignin() {
      pushAuthUrl();
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.password = "";
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}

h2,
h4,
p {
  text-align: left;
  font-family: "Karasuma Gothic", sans-serif;
  font-size: 1.5em;
}

.no-margin-row {
  margin-left: 0;
  margin-right: 0;
}

a {
  color: #00a1ff !important;
  text-decoration: none;
}

a:visited {
  color: #00a1ff !important;
  text-decoration: none;
}

a:hover {
  color: #00a1ff;
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.colored {
  color: #f39200;
}

.register-card {
  padding: 24px;
  border-radius: 12px;
}

.signup-wrapper {
  z-index: 2;
}

.registration-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 39.06px;
  line-height: 36px;
  letter-spacing: 1px;
  color: #ffffff;
}

.hg-logo {
  height: 62px;
  max-width: 80%;
  object-fit: contain;
  object-position: center;
}

.register-btn {
  padding: 8px 12px;
  gap: 12px;
  border-radius: 12px;
}
</style>
