<template>
  <v-col cols="10" lg="7" xl="5" class="signup-wrapper">
    <v-row no-gutters class="mt-10 mb-10" justify="center">
      <img
        src="@assets/landingPage/HG_LOGO.webp"
        alt="hackersgen logo"
        class="hg-logo"
      />
    </v-row>
    <GoBackButton />
    <div class="login-card register-card mb-8">
      <v-row dense>
        <v-col>
          <h2 class="registration-title pb-6">Registrati</h2>
          <v-form v-model="form" class="form-font">
            <v-row dense>
              <v-col cols="12" sm="6" class="pb-0 mb-0">
                <v-text-field
                  v-model="name"
                  label="Nome *"
                  :rules="[rules.required, rules.minTwoCharacters]"
                  outlined
                  dark
                  background-color="transparent"
                  data-cy="input-first-name"
                />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0 mb-2">
                <v-text-field
                  v-model="surname"
                  label="Cognome *"
                  :rules="[rules.required, rules.minTwoCharacters]"
                  outlined
                  dark
                  background-color="transparent"
                  data-cy="input-last-name"
                />
              </v-col>
            </v-row>

            <v-row dense class="mb-1">
              <v-col cols="12" class="mb-0 pb-0">
                <v-text-field
                  v-model="email"
                  required
                  label="Email *"
                  :rules="[rules.email, rules.required, freeEmailRule]"
                  dark
                  :loading="
                    emailFree === null &&
                    email !== '' &&
                    rules.email(email) === true
                  "
                  background-color="transparent"
                  outlined
                  data-cy="input-email"
                  @keyup="onKeyUpEmail"
                  @keydown="onKeyDownEmail"
                >
                  <template #append>
                    <v-icon v-if="emailFree === true">
                      mdi-check-decagram
                    </v-icon>
                  </template>
                </v-text-field>

                <!-- for mobile use this alert instead of tooltip -->
                <v-alert
                  v-if="$vuetify.breakpoint.xsOnly && emailFree === false"
                  type="error"
                >
                  Email già in uso
                </v-alert>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="company"
                  label="Istituto / Azienda"
                  background-color="transparent"
                  dark
                  outlined
                  data-cy="input-company"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min, rules.passwordRules]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Password *"
                  hint="Minimo 8 caratteri"
                  background-color="transparent"
                  dark
                  outlined
                  counter
                  data-cy="input-psw"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="confirmPassword"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, samePassword]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Conferma password *"
                  background-color="transparent"
                  dark
                  outlined
                  counter
                  data-cy="input-psw2"
                  @click:append="showPassword = !showPassword"
                />
              </v-col>
            </v-row>

            <!-- PRIVACY CHECK -->
            <v-row>
              <v-col>
                <p
                  class="white--text font-weight-light text-body-2 text-justify"
                >
                  Sottoscrivendo questa form, do il consenso a processare i miei
                  dati personali, come previsto dalla Legge Europea 679/2016.
                  Per ulteriori dettagli vedi la
                  <a
                    href="https://www.sorint.com/privacy-policy/"
                    target="_blank"
                    class="colored"
                    rel="noopener"
                  >
                    Sorint.lab Privacy Policy
                  </a>
                </p>

                <!--v-checkbox
                  v-model="privacy"
                  label="Accetta Terze Parti *"
                  :rules="[rules.required]"
                  dark
                  disabled
                  hide-details
                  color="#F39200"
                  class="mb-2"
                  data-cy="check-privacy"
                /-->

                <p
                  class="white--text font-weight-light text-body-2 text-justify"
                >
                  <a
                    href="https://www.sorint.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    Sorint.lab S.p.A.</a
                  >
                  dispone di una propria rete di partner autorizzati con il
                  principale obiettivo di fornire il miglior servizio possibile
                  ai propri clienti. Iscrivendoti, accetti che
                  <a
                    href="https://www.sorint.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    Sorint.lab S.p.A.</a
                  >
                  possa condividere parte dei tuoi dati con i suoi partner.
                </p>

                <v-checkbox
                  v-model="newsletter"
                  label="Accetta ricezione newsletter"
                  dark
                  hide-details
                  class="mb-2"
                  data-cy="check-newsletter"
                  color="#F39200"
                />
                <p
                  class="ml-2 white--text font-weight-light text-body-2 text-justify"
                >
                  Accetto che
                  <a
                    href="https://www.sorint.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    Sorint.lab S.p.A.</a
                  >
                  e le sue società affiliate possano utilizzare i miei dati
                  personali per inviarmi informazioni su prodotti, servizi,
                  eventi e offerte speciali tramite email.
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex justify-center justify-sm-start white--text font-weight-light"
          cols="12"
        >
          Sei già registrato?
          <a
            class="ml-1 white--text font-weight-medium"
            @click="() => gotoSignin()"
            >Clicca qui per il login
          </a>
        </v-col>
        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            :loading="loading"
            color="#F39200"
            :disabled="!form || !emailFree"
            dark
            class="register-btn"
            @click="submit"
          >
            Registrati
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import { mapActions } from "vuex";
import api from "@services/publicAxiosInstance";
import GoBackButton from "@cmp-shared/utils/GoBackButton.vue";
import { pushAuthUrl } from "@/utils/externalRouter";

export default {
  name: "SignUpGuest",
  components: { GoBackButton },
  data() {
    return {
      name: "",
      email: "",
      surname: "",
      company: "",
      password: "",
      confirmPassword: "",
      newsletter: true,
      showPassword: false,
      privacy: true,
      form: false,
      isHeadMaster: false,
      loading: false,
      rules: {
        required: (value) => Boolean(value) || "Valore richiesto",
        schoolRequired: (value) =>
          Boolean(value) ||
          "Valore richiesto. Se non trovi la tua scuola, invia una mail a hackersgen@sorint.com",
        min: (v) => v.length >= 8 || "Minimo 8 caratteri" /* have to change */,
        minTwoCharacters: (v) =>
          v.length >= 2 || "Inserisci almeno due caratteri",
        passwordRules: (v) => {
          const errors = [];
          if (v.length < 8) {
            errors.push("almeno 8 caratteri");
          }
          if (!v.match(/[a-z]/)) {
            errors.push("almeno una lettera minuscola");
          }
          if (!v.match(/[A-Z]/)) {
            errors.push("almeno una lettera maiuscola");
          }
          if (!v.match(/[0-9]/)) {
            errors.push("almeno un numero");
          }
          return errors.length > 0
            ? `La password deve contenere ${errors.join(", ")}`
            : true;
        },
        email: (v) =>
          !v ||
          /^\s*(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/.test(
            v
          ) ||
          `Indirizzo email non valido`,
      },
      emailTimeout: null,
      emailFree: null,
    };
  },
  computed: {
    samePassword() {
      return (
        this.password === this.confirmPassword || "Le password non coincidono"
      );
    },
    freeEmailRule() {
      return this.emailFree !== false || "La mail selezionata è già in uso";
    },
  },
  methods: {
    ...mapActions("auth", ["signUpGuest"]),
    async submit() {
      this.loading = true;
      try {
        await this.signUpGuest({
          name: this.name,
          surname: this.surname,
          email: this.email.trim().toLowerCase(),
          password: this.password,
          company: this.company,
          newsletter: this.newsletter,
          privacy: this.privacy,
        });
        pushAuthUrl();
      } finally {
        this.loading = false;
      }
    },
    gotoSignin() {
      pushAuthUrl();
    },
    onKeyDownEmail() {
      if (this.rules.email(this.email) === true) {
        this.emailFree = null;
      }
      clearTimeout(this.emailTimeout);
    },
    onKeyUpEmail() {
      this.emailTimeout = setTimeout(this.checkEmailFree, 1500);
    },
    async checkEmailFree() {
      if (this.rules.email(this.email) === true) {
        try {
          const { data } = await api.get("check-email", {
            params: { email: this.email.trim().toLowerCase() },
          });
          this.emailFree = data.is_free;
        } catch (err) {
          /* do nothing */
        }
      }
    },
  },
};
</script>

<style scoped>
.gradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    180deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(65, 9, 121, 1) 0%,
    rgba(65, 9, 121, 0) 100%
  );
}

.signup-wrapper {
  z-index: 2;
}

h2,
h4,
p {
  text-align: left;
  font-family: "Karasuma Gothic", sans-serif;
  font-size: 1.5em;
}

.no-margin-row {
  margin-left: 0;
  margin-right: 0;
}

a {
  color: #00a1ff !important;
  text-decoration: none;
}

a:visited {
  color: #00a1ff !important;
  text-decoration: none;
}

a:hover {
  color: #00a1ff;
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

.block {
  display: block;
}

.colored {
  color: #f39200;
}

.register-card {
  padding: 24px;
  border-radius: 12px;
}

.registration-title {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 39.06px;
  line-height: 36px;
  letter-spacing: 1px;
  color: #ffffff;
}

.hg-logo {
  height: 62px;
  max-width: 80%;
  object-fit: contain;
  object-position: center;
}

.register-btn {
  padding: 8px 12px;
  gap: 12px;
  border-radius: 12px;
}
</style>
